import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS } from '../../../constants/colors';
import { BaseTitle } from '../../components/menu/subtitles/layout.styled';
import SVG from '../../components/svg';
import MenuItem from './MenuItem';

export const disabledStyle = css`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const SectionWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  height: 100%;
  opacity: ${({ disabled }): string => (!disabled ? '1' : '0.5')};
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'all')};
`;

export const StyledMenuItem = styled(MenuItem)`
  padding-block: 12px !important;
  padding-inline: 0 !important;
  border-bottom: 1px solid #e6e9ef;

  .menu-item-label {
    margin: 0 0 0 2px;
  }
`;

export const MenuHeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px !important;
  border-bottom: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 10px;

  .menu-header {
    width: 100%;
     padding: 3px 20px 13px 20px;

  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 7%;
    right: 7%;
    height: 0.4px;
    background-color: ${COLORS.peech_gray_300};
  }
`;

export const MenuHeaderWrapperWithSwitch = styled(MenuHeaderWrapper)<{ marginBottom?: number }>`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  top: 6px;
  padding-inline: 10px;
  padding-bottom: 14px;
  margin-bottom: ${props => props.marginBottom+'px' || '26px'} !important;
  &:after {
    left: 2%;
    right: 2%;
  }
`;

export const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tabs-group-header {
    width: 95%;
    max-width: 95%;
    margin: 0;
  }
  .tabs-group-tabs {
    margin: 0;
    width: 100%;
    max-width: 100%;
    button {
      width: 50%;
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
  }
`;
export const FakeTooltip = styled.div`
  width: 90%;
  height: fit-content;
  min-height: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
  background: #f2f2f2;
  border-radius: 10px;
  padding: 8px 15px 8px 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  span {
    white-space: pre-wrap;
  }
`;

export const FakeHighLightToolTip = styled(FakeTooltip)`
  background: ${COLORS.peech_primary_2_50};
  color: ${COLORS.peech_primary_2_500};
  svg {
    path {
      fill: ${COLORS.peech_primary_2_500};
    }
  }
`;

export const IconWrapper = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.div<{ hide?: boolean }>`
  margin-bottom: 24px;
  display: ${({ hide }): string => (hide ? 'none' : 'block')};
`;

export const UnderLinedSection = styled(Section)`
  position: relative;
  padding-bottom: 20px;
  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 5px;
    right: 5px;
    height: 0.4px;
    background-color: ${COLORS.peech_gray_300};
  }
`;

export const BothLinedSection = styled(UnderLinedSection)`
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    height: 1px;
    left: 5px;
    right: 5px;
    height: 0.4px;
    background-color: ${COLORS.peech_gray_300};
  }
`;

export const SubSection = styled(Section)`
  margin-block: 16px;
  padding-inline: 2px;
`;

export const SectionTitle = styled(BaseTitle)`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;


  margin-bottom: 6px;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 16px;
  }
`;

export const SubSectionTitle = styled(BaseTitle)`
  margin-bottom: 6px;
`;

export const OptionsBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Limit = styled.div`
  height: 100%;
  min-width: 30px;
  padding: 1px 5px 0px 5px;
  color: ${COLORS.peech_gray_400};
  display: flex;
  align-items: flex-end;
`;

export const Stack = styled.div<{ spacing: number; disabled?: boolean }>`
  display: flex;
  width: 100%;
  align-items: baseline;
  ${(props) => props.disabled && disabledStyle}

  > *:not(:last-child) {
    margin-right: ${(props) => props.spacing}px;
  }
`;

export const OutlinedStack = styled(Stack)`
  position: relative;
  padding-bottom: 20px;
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    height: 1px;
    left: 0;
    right: 0;
    height: 0.4px;
    background-color: #e6e9ef;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 0;
    right: 0;
    height: 0.4px;
    background-color: #CCCCCC;
  }
`;

export const ArrowDownIcon = styled(SVG)`
  fill: #999999;
  width: 20px;
  height: 20px;
  rotate: ${(props) => (props.rotate ? -180 : 0)}deg;
  transition: rotate 250ms ease-out;
`;

ArrowDownIcon.defaultProps = {
  name: 'arrow-down',
};

export const BlockHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50px;
`;

export const HeaderActionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

export const LabelDiv = styled.div`
  width: 50px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.peech_neutral_black};
`;

export const TimeContainerWrapper = styled.div<{ hide: boolean }>`
  width: 115px;
  display: ${({ hide }): string => (hide ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 6px;
  input {
    color: ${COLORS.peech_gray_400};
  }
`;

export const ShowHideDiv = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 6px;
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'all')};

  svg {
    width: 22px;
    fill: ${({ disabled }): string => (disabled ? COLORS.peech_gray_300 : COLORS.peech_gray_500)};
    stroke: ${({ disabled }): string => (disabled ? COLORS.peech_gray_300 : 'none')};
    .slash {
      stroke: ${({ disabled }): string => (disabled ? COLORS.peech_gray_300 : COLORS.peech_gray_500)};
    }
  }

  &:hover {
    svg {
      fill: ${COLORS.peech_gray_400};
      transition: fill 250ms ease-out;
      .slash {
        stroke: ${COLORS.peech_gray_400};
        transition: stroke 250ms ease-out;
      }
    }
  }
`;

export const ExpandDiv = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoldButton = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  svg {
    fill: ${({ isSelected }): string => (isSelected ? COLORS.peech_primary_2_500 : COLORS.peech_neutral_black)};
  }
  &:hover {
    svg {
      fill: ${COLORS.peech_gray_400};
      transition: fill 250ms ease-out;
    }
  }
`;

export const Breaker = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e6e9ef;
  margin-bottom: 20px;
`;

export const StyledLabel = styled.label`
    display: flex;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
`
